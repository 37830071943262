<!--
   User: Liu Yin
   Date: 2020/3/13
   Description:
 -->
<template>
  <article class="flexcenbet">
    <!-- <div style="float: left;cursor: pointer" @click="toMain">
      <img :src="getImageSrc()" class="logo">
      <div style="margin-left: 20px;color: white;padding-top: 10px;">
        <p class="cn-font">{{ parkData.parkName }}</p>
      </div>
    </div> -->
    <!-- <div class="svgDiv">
      <svg-icon icon-class="logo"></svg-icon>
    </div> -->
    <img :src="getImageSrc()" class="logo">
    <a class="registerfont" :href="linkRegister" target="_blank">
      <div class="register">
        注册
      </div>
    </a>
  </article>
</template>

<script>
import logoIcon from '../../assets/images/login/logo-deep.png'
export default {
  data() {
    return {
      linkRegister: process.env.VUE_APP_ENTERPRISE_URL + '/register'
    }
  },
  computed: {
    parkData() {
      return this.$store.getters.parkData
    }
  },
  methods: {
    getImageSrc() {
      if (!this.parkData.parkLogo) {
        return logoIcon
      } else {
        return this.parkData.parkLogo
      }
    },
    toMain() {
      location.href = '/'
    },
    changeLoginType(type) {
      this.$emit('changeLoginType', type)
    }
  }
}
</script>

<style lang="scss" scoped>
// .svgDiv {
//   height: 60px;
// }
// svg {
//   font-size: 180px;
//   margin-top: -61px;
//   margin-left: 50px;
// }
.login-top {
  background-image: url("../../assets/images/head-bg.jpg");
  background-position: 50% 50%;
  background-size: 100% 100%;
  height: 60px;
  > div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .logo {
    // max-height: 45px;
    width: 135px;
    height: 35px;
    border-radius: 5px;
  }
  .cn-font {
    font-size: 20px;
    letter-spacing: 10px;
  }
  .en-font {
    margin-top: 5px;
    font-size: 10px;
  }
}
.register {
  width: 80px;
  height: 32px;
  line-height: 32px;
  background: #3369ff;
  border-radius: 1px;
  text-align: center;
  letter-spacing: 0;
  color: #fff;
  cursor: pointer;
}
.registerfont {
  color: #fff;
  margin-right: 50px;
}
.flexcenbet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
}
.logo {
  // width: 163px;
  height: 30px;
  margin-left: 50px;
}
</style>
